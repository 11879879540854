import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import styled from '@emotion/styled';
import { Section } from '@/components/elements';
import BasicForm from '@/components/ContactForm/BasicForm';

interface ComponentProps {
  title?: string;
  bgColor?: string;
  fullwidth?: boolean;
  label?: {
    name: string;
    email: string;
    message: string;
    messagePlaceholder: string;
    submit: string;
  };
}
interface DivProps {
  bgColor: string;
}

const Header = styled.div<DivProps>`
  color: ${(props) =>
    props.bgColor === `primary`
      ? props.theme.palette.primary.contrastText
      : `inherit`};
`;
const Content = styled.div<DivProps>`
  color: ${(props) =>
    props.bgColor === `primary`
      ? props.theme.palette.primary.contrastText
      : `inherit`};
`;

const ContactForm: React.FC<ComponentProps> = ({
  title,
  bgColor,
  fullwidth,
  label,
}) => (
  <Section bgColor={bgColor} fullwidth={fullwidth}>
    {title && (
      <Header bgColor={bgColor}>
        <h2>{title}</h2>
      </Header>
    )}
    <Content bgColor={bgColor}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY}
      >
        <BasicForm label={label} />
      </GoogleReCaptchaProvider>
    </Content>
  </Section>
);

ContactForm.defaultProps = {
  title: null,
  bgColor: ``,
  fullwidth: false,
  label: {
    name: `Your Name`, //  お名前
    email: `Your Email Address`, //  メールアドレス
    message: `Message`, //  メッセージ
    messagePlaceholder: `Your message..`, //  ご質問はこちらへどうぞ
    submit: `Send`, //  メッセージを送る
  },
};

export default ContactForm;
