import React from 'react';
import { PageProps } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '@/components/Layout';
import { PageContainer, PageHeader } from '@/components/elements';
import Seo from '@/components/Seo';
import ContactForm from '@/components/ContactForm';

const ContactPage: React.FC<PageProps> = () => (
  <Layout>
    <Seo title="Contact | MyServerJapan" description="" lang="en" />
    <PageContainer>
      <PageHeader
        title="CONTACT"
        subtitle="Please feel free to contact us anytime!"
        bgImage={
          <StaticImage
            src="../assets/images/about-header-bg.png"
            alt=""
            quality={100}
            layout={`fixed`}
            height={300}
          />
        }
        bgColor={`#db0000`}
      />
      <ContactForm />
    </PageContainer>
  </Layout>
);

export default ContactPage;
