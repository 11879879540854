import React, { useState } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import axios from 'axios';
import ReactiveButton, { buttonState } from 'reactive-button';
import styled from '@emotion/styled';

interface ComponentProps {
  label?: {
    name: string;
    email: string;
    message: string;
    messagePlaceholder: string;
    submit: string;
  };
}

const FormContainer = styled.div``;

const FormInput = styled.input`
  border-radius: 0.5rem;
  outline: none;
  border: solid 1px ${(props) => props.theme.palette.primary.main};
  display: block;
  width: 100%;
  opacity: 1;
  padding: 1em;
  margin-bottom: 2rem;

  &:focus {
    border-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const FormTextArea = styled.textarea`
  background: none;
  outline: none;
  border-radius: 0.5rem;
  border: solid 1px ${(props) => props.theme.palette.primary.main};
  color: inherit;
  display: block;
  outline: 0;
  padding: 1em;
  text-decoration: none;
  width: 100%;
  opacity: 1;
  margin-bottom: 2rem;

  &:focus {
    border-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

const SubmitButton = styled(ReactiveButton)`
  background-color: ${(props) => props.theme.palette.primary.main};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
  &.disabled {
    background-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const ResponseContainer = styled.div`
  margin-top: 1rem;
`;

const BasicForm: React.FC<ComponentProps> = ({ label }) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [formStatus, setFormStatus] = useState<buttonState>(`idle`);
  const [errorMessage, setErrorMessage] = React.useState(``);
  const [isDisabled, setDisabled] = useState(false);
  const [fname, setFname] = useState(``);
  const [email, setEmail] = useState(``);
  const [message, setMessage] = useState(``);

  const handleFormReset = () => {
    setEmail(``);
    setFname(``);
    setMessage(``);
  };

  const onSubmit = async () => {
    setFormStatus(`loading`);
    setDisabled(true);

    if (!executeRecaptcha) {
      setFormStatus(`error`);
      setDisabled(false);
      return;
    }

    const recaptchaToken = await executeRecaptcha(`contactForm`);

    const formData = {
      toEmail: process.env.GATSBY_MYSERVERJAPAN_API_CONTACT_FORM_TO_EMAIL,
      fromEmail: `noreply@myserverjapan.com`,
      fromName: `MyServerJapan`,
      subject: `MyserverJapan Contact Form`,
      message: `
      Name / 氏名: ${fname} <br>
      Email / メール: ${email} <br>
      Message / ご質問など: ${message} <br>
      `,
      recaptchaSiteKey: process.env.GATSBY_GOOGLE_RECAPTCHA_SITE_KEY,
      recaptchaToken,
      formId: `contactForm`,
      sendCopy: true,
      language: `en`,
      sendHtml: true,
    };

    const body = JSON.stringify(formData);

    const config = {
      baseURL: process.env.GATSBY_MYSERVERJAPAN_API_BASE_URL,
      headers: {
        Authorization: `Bearer ${process.env.GATSBY_MYSERVERJAPAN_API_KEY}`,
        'Content-Type': `application/json`,
      },
    };

    axios
      .post(process.env.GATSBY_MYSERVERJAPAN_API_CONTACT_FORM_URL, body, config)
      .then((res) => {
        setTimeout(() => {
          setFormStatus(`success`);
          handleFormReset();
          setDisabled(false);
        }, 3000);
        console.log(`res`, res);
      })
      .catch((err) => {
        console.log(`err`, err);
        if (err.response) {
          setErrorMessage(err.response.data.code);
        } else {
          setErrorMessage(`Something went wrong.`); // unknown
        }
        setFormStatus(`error`);
        handleFormReset();
        setDisabled(false);
      });
  };

  return (
    <FormContainer>
      <form>
        <div className="contact-form row">
          <FormInput
            id="contactName"
            name="fname"
            type="text"
            placeholder={label.name}
            onChange={(e) => setFname(e.target.value)}
          />

          <FormInput
            id="contactEmail"
            type="email"
            name="email"
            placeholder={label.email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <FormTextArea
            id="contactMessage"
            name="message"
            placeholder={label.messagePlaceholder}
            rows={6}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="actions special">
          <SubmitButton
            buttonState={formStatus}
            onClick={onSubmit}
            type={`button`}
            disabled={isDisabled}
            idleText={label.submit}
            rounded
            size="large"
            animation
            buttonRef={null}
            height={null}
            width={null}
          />
        </div>
      </form>
      <ResponseContainer>
        {formStatus === `error` && <div>{errorMessage}</div>}

        {formStatus === `success` && <div>Your Message has been sent!</div>}
      </ResponseContainer>
    </FormContainer>
  );
};

BasicForm.defaultProps = {
  label: {
    name: `Your Name`, //  お名前
    email: `Your Email Address`, //  メールアドレス
    message: `Message`, //  メッセージ
    messagePlaceholder: `Your message..`, //  ご質問はこちらへどうぞ
    submit: `Send`, //  メッセージを送る
  },
};

export default BasicForm;
